.drop-active {
    border-radius: 4px;
    border: solid 1px rgba(211, 207, 194, 0.49);
    background-color: #ffffff;
    min-height: 89px;
    margin-bottom: 20px;
    cursor: pointer;

    .upload-label-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 24px;

        .icon-wrap {
            margin-top: 21px;
            margin-bottom: 10px;

            .fa-cloud-upload {
                font-size: 1.3em;
            }
        }

        .label {
            color: $brown-grey !important;
            cursor: pointer;
            text-align: center;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 500;
        }

        label {
            .color-error {
                color: #ff7f7e !important;
                font-style: italic;
                margin-bottom: 15px;
            }
        }

        .loading-gif {
            margin-top: 17px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
