
.form-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(96vh - 75px);
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    & > div {
        width: 75%;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.map-container {
    height: calc(96vh - 75px);
}

.header-container {
    padding-left: 3rem;
    padding-right: 3rem;
    border-bottom-width: 2px;
    border-style: solid;
    position: sticky;
    top: 0;
    z-index: 10;
    margin-top: 0.5rem;

    .header-actions {
        margin-top: 0;
    }
}

.header-tab {
    &:hover {
        font-family: 'Avenir-Medium', 'Avenir-Heavy', 'sans-serif';
    }
}

.avenir-bold {
    font-family: 'Avenir-Medium', 'Avenir-Heavy', 'sans-serif';
}

.is-liked {
    position: absolute;
    top: 1px;
    right: -4px;
    z-index: 20;
}

.is-recommended {
    position: absolute;
    top: -4px;
    right: 1px;
    z-index: 10;
}

@media (max-width: 691px) {
    .form-container {
        height: 100%;

        & > div {
            width: 100%;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }
    }

    .map-container {
        display: none;
    }

    .header-container {
        flex-direction: column;
        margin-top: 0.5rem;
        margin-bottom: 0rem;

        .header-actions {
            margin-top: 1rem;
        }
    }
}
