
.tags-input {
    .tags {
        padding: 4px;
        .ti-input {
            border: 0;
            padding: 0;
        }
        li.ti-tag {
            background-color: black;
        }
    }
}
