
#hide-layers {
    font-family: Avenir;
    font-style: italic;
    font-weight: 500;
    color: #9b9b9b;
}

.clickable {
    cursor: pointer;

    &:hover {
        color: #9b9b9b;
    }
}
