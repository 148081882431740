
.subpanel {
    background-color: white;
    border-radius: 3px;
    margin-bottom: 9px !important;

    .name {
        width: 285px;
        padding: 0 55px 0 5px;
        font-size: 12px;
    }

    .close-icon {
        margin-right: 10px;
        cursor: pointer;
    }

    .drag-handle {
        margin-left: 5px;

        .draggable-icon {
            vertical-align: middle;
        }
    }
}
